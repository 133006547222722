* {
    padding: 0;
    margin: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;
}

.upload-container {
    width: 500px;
    position: absolute;
    height: 100%;
    background-color: gray;
    opacity: 0.2;
    z-index: 100;
    border-radius: 10px;
}
