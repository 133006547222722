.sider {
    min-height: calc(100vh - 72px);
    background-color: #215294;
    padding: 5px;
}
.item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 72px;
    padding: 12px 4px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
}

.subMenu {
    position: absolute;
    opacity: 0;
    padding: 10px;
    visibility: hidden;
    width: 200px;
    left: 90px;
    top: 0px;
    background-color: #215294;
    border-radius: 10px;
    z-index: 3;
    transition: opacity 0.3s linear;
}

.subMenuItem {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: rgba(255, 255, 255, 1);
    padding: 10px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subMenuItem:hover {
    background-color: #38649f;
}

.item:hover {
    background-color: #38649f;
}
.itemTitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: rgba(255, 255, 255, 1);
}
