.container{
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
}

.loginForm {
    max-width: 300px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 35px -12px rgba(0, 0, 0, 0.2);
}

.logo {
    margin: 15px 0;
    text-align: center;
}

.forgot {
    float: right;
}

.ant-col-rtl .forgot{
    float: left;
}

.button {
    width: 100%;
}